<template>
  <div class="home">
    <h1>Welcome to MathDash</h1>
    <h3>A place to practice your math skills!</h3>
    <img src="../assets/math-dash.svg" alt="Mathdash logo" />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>
