import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import UnitCirclePractice from "../views/UnitCirclePractice";
import MultiplicationPractice from "../views/MultiplicationPractice";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/unit-circle-practice",
    name: "Unit Circle",
    component: UnitCirclePractice,
  },
  {
    path: "/multiplication-practice",
    name: "Multiplication Practice",
    component: MultiplicationPractice,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
