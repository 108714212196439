<template>
  <unit-circle />
</template>

<script>
import UnitCircle from "../components/UnitCircle.vue";
export default {
  name: "UnitCirclePractice",
  components: { UnitCircle },
};
</script>

<style></style>
