<template>
  <multiplication-practice />
</template>

<script>
import MultiplicationPractice from "../components/Multiplication.vue";
export default {
  name: "UnitCirclePractice",
  components: { MultiplicationPractice },
};
</script>

<style></style>
