<template>
  <div class="flex-container main-container">
    <div class="main-content">
      <h1 id="title">Multiplication Practice</h1>
      <div class="problem-stem">
        <span class="factor-label-a"> \({{ factorA }} \) </span> \( \times \)
        <span class="factor-label-b">\({{ factorB }}\)</span>
      </div>
      <div class="problem-result">{{ result }}</div>
    </div>

    <div class="controls">
      <div class="controls-parameters">
        <div class="controls-row">
          <div>
            <div v-if="shuffleModeA">
              <div>
                <label class="factor-label-a" for="min-factor-a">Min. A</label>
                <input
                  v-model="minFactorA"
                  type="number"
                  name="min-factor-a"
                  id="min-factor-a"
                />
              </div>
              <div>
                <label class="factor-label-a" for="max-factor-a">Max A</label>
                <input
                  v-model="maxFactorA"
                  type="number"
                  name="max-factor-a"
                  id="max-factor-a"
                />
              </div>
            </div>
            <div v-else>
              <label class="factor-label-a" for="factor-a">A</label>
              <input
                v-model="factorA"
                type="number"
                name="factor-a"
                id="factor-a"
              />
            </div>
          </div>

          <input
            v-model="shuffleModeA"
            type="checkbox"
            name="shuffle-mode-a"
            id="shuffle-mode-a"
          />
        </div>
        <!-- Insert B Here -->
        <div class="controls-row">
          <div>
            <div v-if="shuffleModeB">
              <div>
                <label class="factor-label-b" for="min-factor-b">Min. B</label>
                <input
                  v-model="minFactorB"
                  type="number"
                  name="min-factor-b"
                  id="min-factor-b"
                />
              </div>
              <div>
                <label class="factor-label-b" for="max-factor-b">Max B</label>
                <input
                  v-model="maxFactorB"
                  type="number"
                  name="max-factor-b"
                  id="max-factor-b"
                />
              </div>
            </div>
            <div v-else>
              <label class="factor-label-b" for="factor-b">B</label>
              <input
                v-model="factorB"
                type="number"
                name="factor-b"
                id="factor-b"
              />
            </div>
          </div>
          <input
            v-model="shuffleModeB"
            type="checkbox"
            name="shuffle-mode-b"
            id="shuffle-mode-b"
          />
        </div>
      </div>
      <div class="controls-buttons">
        <button v-if="shuffleModeA || shuffleModeB" @click="randomFactor">
          <i class="gg-ratio"></i>
        </button>

        <div v-else class="controls-regular">
          <button @click="subtractOne"><i class="gg-math-minus"></i></button>
          <button @click="addOne"><i class="gg-math-plus"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { renderMathInDocument } from "mathlive";
import "mathlive/dist/mathlive-static.css";
export default {
  name: "MultiplicationPractice",
  data() {
    return {
      factorA: 5,
      factorB: 10,
      minFactorA: 1,
      maxFactorA: 20,
      minFactorB: 1,
      maxFactorB: 20,
      shuffleModeA: false,
      shuffleModeB: false,
    };
  },
  methods: {
    subtractOne() {
      return this.factorB--;
    },
    addOne() {
      return this.factorB++;
    },
    randomFactor() {
      if (this.shuffleModeB == true) {
        this.factorB = Math.floor(
          Math.random() * (this.maxFactorB - this.minFactorB + 1) +
            this.minFactorB
        );
      }
      if (this.shuffleModeA == true) {
        this.factorA = Math.floor(
          Math.random() * (this.maxFactorA - this.minFactorA + 1) +
            this.minFactorA
        );
      }
    },
  },
  computed: {
    result() {
      return this.factorA * this.factorB;
    },
  },
  mounted() {
    renderMathInDocument();
  },
  updated() {
    renderMathInDocument();
  },
};
</script>

<style scoped>
/* * {
  border: 1px solid white;
} */

.flex-container {
  display: flex;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
}

.main-container {
  flex-direction: column;
  height: 100vh;
}
#title {
  font-size: 1.5rem;
}

.main-content {
  margin-top: 5rem;
}

.problem-stem {
  font-size: 2.5rem;
  color: var(--light-green);
}

.problem-result {
  font-size: 4.5rem;
  color: var(--bright-orange);
  font-weight: bold;
}

.controls {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: space-between;
  margin: 1.5rem;
}
.controls label {
  font-weight: bold;
}

.controls-parameters {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.controls-row {
  display: flex;
}

.controls-row > div > div > div {
  margin: 10px;
}

.factor-label-a {
  color: var(--light-green);
}

.factor-label-b {
  color: var(--light-pink);
}

.controls input {
  margin: 4px;
  width: 25%;
}

#shuffle-mode-a,
#shuffle-mode-b {
  margin: auto;
  width: 40px;
}

.controls-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.controls-buttons button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin: 1em;
  background-color: var(--light-green);
}

button i {
  border: none;
  margin: auto;
}

.controls-regular {
  display: flex;
  flex-direction: column;
}
</style>
