<template>
  <div class="svg-canvas"></div>
  <!-- <h1>{{ angleIndex }}</h1> -->
  <h2>\({{ composeQuestion }}\)</h2>
  <button @click="newProblem()">Next Problem</button>
</template>

<script>
import * as d3 from "d3";
import { renderMathInDocument } from "mathlive";
import "mathlive/dist/mathlive-static.css";
export default {
  name: "UnitCircle",
  data() {
    return {
      width: 800,
      height: 800,
      angleIndex: 0,
      trigValues: [
        {
          stringAngle: "0",
          angle: 0,
          cosine: "1",
          sine: "0",
        },
        {
          stringAngle: "\\frac{\\pi}{6}",
          angle: Math.PI / 6,
          cosine: "\\frac{\\sqrt{3}}{2}",
          sine: "\\frac{1}{2}",
        },
        {
          stringAngle: "\\frac{\\pi}{4}",
          angle: Math.PI / 4,
          cosine: "\\frac{\\sqrt{2}}{2}",
          sine: "\\frac{\\sqrt{2}}{2}",
        },
        {
          stringAngle: "\\frac{\\pi}{3}",
          angle: Math.PI / 3,
          cosine: "\\frac{1}{2}",
          sine: "\\frac{\\sqrt{3}}{2}",
        },
        {
          stringAngle: "\\frac{\\pi}{2}",
          angle: Math.PI / 2,
          cosine: "0",
          sine: "1",
        },
      ],
    };
  },
  computed: {
    circlePoint() {
      return {
        cx:
          this.width / 2 +
          this.circle.r * Math.cos(this.trigValues[this.angleIndex].angle),
        cy:
          this.height / 2 -
          this.circle.r * Math.sin(this.trigValues[this.angleIndex].angle),
        r: "4px",
      };
    },
    circle() {
      return {
        cx: this.width / 2,
        cy: this.height / 2,
        r: this.width / 2 - 75,
      };
    },
    composeQuestion() {
      let num = Math.floor(Math.random() * 2);
      if (num % 2) {
        return (
          "\\cos\\left(" +
          this.trigValues[this.angleIndex].stringAngle +
          "\\right) = " +
          this.trigValues[this.angleIndex].cosine
        );
      } else {
        return (
          "\\sin\\left(" +
          this.trigValues[this.angleIndex].stringAngle +
          "\\right) = " +
          this.trigValues[this.angleIndex].sine
        );
      }
    },
  },
  methods: {
    drawCanvas() {
      // Draw SVG canvas
      d3.selectAll(".svg-canvas")
        .append("svg")
        .attr("class", "graph")
        .attr("width", this.width)
        .attr("height", this.height);
      // Draw Vertical Axis
      d3.selectAll(".graph")
        .append("line")
        .attr("class", "axis")
        .attr("x1", this.width / 2)
        .attr("y1", 0)
        .attr("x2", this.width / 2)
        .attr("y2", this.height);
      // Draw Horizontal Axis
      d3.selectAll(".graph")
        .append("line")
        .attr("class", "axis")
        .attr("x1", 0)
        .attr("y1", this.width / 2)
        .attr("x2", this.width)
        .attr("y2", this.height / 2);
    },
    drawCircle() {
      d3.selectAll(".graph")
        .append("circle")
        .attr("class", "circle")
        .attr("cx", this.circle.cx)
        .attr("cy", this.circle.cy)
        .attr("r", this.circle.r);
    },
    drawPoint() {
      d3.selectAll(".graph")
        .append("circle")
        .attr("class", "point")
        .attr("cx", this.circlePoint.cx)
        .attr("cy", this.circlePoint.cy)
        .attr("r", this.circlePoint.r);
    },
    drawTriangle() {
      d3.selectAll(".graph")
        .append("polygon")
        .attr("class", "triangle")
        .attr(
          "points",
          `${this.circle.cx}, ${this.circle.cy} ${this.circlePoint.cx}, ${
            this.circlePoint.cy
          } ${this.circlePoint.cx}, ${this.width / 2}`
        );
    },
    drawPointText() {
      // Draw Point Text
      d3.selectAll(".graph")
        .append("foreignObject")
        .attr("class", "point-text")
        .attr("x", this.circlePoint.cx)
        .attr("y", this.circlePoint.cy - 50)
        .attr("width", 150)
        .attr("height", 150)
        .text(
          "\\( \\left(" +
            this.trigValues[this.angleIndex].cosine +
            ", " +
            this.trigValues[this.angleIndex].sine +
            " \\right)\\)"
        );
    },
    drawAngleText() {
      d3.selectAll(".graph")
        .append("foreignObject")
        .attr("class", "point-text")
        .attr(
          "x",
          this.width / 2 +
            0.5 *
              this.circle.r *
              Math.cos(this.trigValues[this.angleIndex].angle)
        )
        .attr(
          "y",
          this.height / 2 -
            0.5 *
              this.circle.r *
              Math.sin(this.trigValues[this.angleIndex].angle)
        )
        .attr("width", 150)
        .attr("height", 150)
        .text("\\(" + this.trigValues[this.angleIndex].stringAngle + "\\)");
    },
    newProblem() {
      this.angleIndex = Math.floor(Math.random() * this.trigValues.length);
    },
    updatedCanvas() {
      d3.selectAll(".triangle, .point, .point-text").remove();
      this.drawTriangle();
      this.drawPoint();
      this.drawAngleText();
      this.drawPointText();
      renderMathInDocument();
    },
  },
  mounted() {
    this.drawCanvas();
    this.drawCircle();
    renderMathInDocument();
  },
  updated() {
    this.updatedCanvas();
  },
};
</script>

<style>
.graph {
  border: 1px solid black;
  stroke: black;
  stroke-width: 2px;
}
.axis {
  border: 1px solid black;
  stroke: black;
  stroke-width: 2px;
}
.circle {
  border: 1px solid black;
  stroke: black;
  stroke-width: 1px;
  fill: none;
}
.triangle {
  fill: aqua;
  fill-opacity: 0.75;
  stroke: none;
}
.point-text {
  stroke-width: 1px;
  border: 1px solid black;
  font-size: 1.5rem;
  text-align: left;
}
</style>
